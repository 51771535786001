import { useLocalStorageState } from 'ahooks';
import { Form, Radio } from 'antd';
import { isNil } from 'lodash-es';

export function UsedShowWhatControlPanel() {
	const [form] = Form.useForm();
	const showWhat = Form.useWatch('showWhat', form);
	const [stableValue, setStableValue] = useLocalStorageState('showWhat', {
		defaultValue: '全部',
	});
	const isShowColdWater = showWhat === '全部' || showWhat === '冷水';
	const isShowHotWater = showWhat === '全部' || showWhat === '热水';
	const isShowElectron = showWhat === '全部' || showWhat === '电';

	return {
		isShowColdWater,
		isShowHotWater,
		isShowElectron,

		controlPanel: (
			<Form
				form={form}
				initialValues={{
					showWhat: stableValue,
				}}
				onValuesChange={(_, val) => {
					if (!isNil(val.showWhat)) {
						setStableValue(val.showWhat);
					}
				}}
			>
				<Form.Item label="读数显示" name="showWhat">
					<Radio.Group>
						<Radio value={'全部'}>全部</Radio>
						<Radio value={'冷水'}>冷水</Radio>
						<Radio value={'热水'}>热水</Radio>
						<Radio value={'电'}>电</Radio>
					</Radio.Group>
				</Form.Item>
			</Form>
		),
		showWhat: showWhat,
	};
}
